export interface Compliance {
  status: ComplianceStatus;
  type: ComplianceType;
  validUntil: Date | string;
}

export enum ComplianceStatus {
  VALID = "Valid",
  INVALID = "Invalid",
  PENDING = "Pending",
  NOT_STARTED = "NotStarted",
  MISSING_USER_FIRST_NAME = "MissingUserInfoWebSsoFirstName",
  MISSING_USER_LAST_NAME = "MissingUserInfoWebSsoLastName",
  MISSING_USER_COMPANY = "MissingUserInfoWebSsoCompany",
  MISSING_USER_COUNTRY = "MissingUserInfoWebSsoCountry",
  MISSING_USER_CITY = "MissingUserInfoWebSsoCity",
  MISSING_USER_STREET = "MissingUserInfoWebSsoStreet",
}

export enum ComplianceType {
  DAMEXSPS = "DamexSps",
}
